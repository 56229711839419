import React, { Component, Fragment } from 'react' // eslint-disable-line no-unused-vars
import { Link } from 'gatsby'
import ReactModal from 'react-modal'
import Headroom from 'react-headroom'
import Button from './Button'
import Nav from './Nav'
import siteLogo from '@/icons/site-logo.svg'
import searchIcon from '@/icons/ui-search.svg'
import BetterIcon from '@/components/utils/BetterIcon'
import SearchModal from '@/components/SearchModal/SearchModal'

const content = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	border: 0,
	background: 'none',
	overflow: 'auto',
	borderRadius: 0,
	outline: 'none',
	padding: '1.25rem',
	width: '100%'
}

export default class Header extends Component {
	state = {
		isOpen: false,
		isMenuOpen: false
	}

	componentDidUpdate = ({ location }) => {
		const { isMenuOpen } = this.state
		const { location: newLocation } = this.props

		if (location !== newLocation && isMenuOpen) {
			this.setState({
				isMenuOpen: false
			})

			if (window) {
				document.body.style.overflow = ''
			}
		}
	}

	handleClose = () => {
		this.setState(({ isOpen }) => ({
			isOpen: !isOpen
		}))
	}

	render() {
		const { isOpen, isMenuOpen } = this.state
		const { dark } = this.props

		return (
			<Fragment>
				<Headroom>
					<header
						className={`flex h-80 xl:h-100 justify-end xl:justify-center items-center lg:bg-grey-dark ${
							dark ? 'bg-grey-dark' : ''
						}`}
					>
						<Link
							to="/"
							className="w-150 md:w-200 max:w-250 mr-auto glitch inline-block mx-1 my-0-75 outline-none text-ms-6 md:text-ms-10 max:text-ms-14 font-bold"
							data-text="straightline"
						>
							<BetterIcon icon={siteLogo} className="w-full" />

							<span className="visuallyhidden">We are straightline</span>
						</Link>
						<Nav isOpen={isMenuOpen} />

						<button
							type="button"
							className="xl:bg-teal p-0-5 lg:p-1-25 xl:ml-auto h-full w-70 lg:w-100 xl:block hover:bg-orange focus-box outline-none trans trans trans-slow"
							onClick={e => {
								e.preventDefault()
								this.handleClose()
							}}
						>
							<span className="visuallyhidden">search</span>
							<BetterIcon icon={searchIcon} className="w-25 lg:w-30 mx-auto" />
						</button>
						<Button
							isOpen={isMenuOpen}
							onClick={e => {
								e.preventDefault()

								this.setState({ isMenuOpen: !isMenuOpen }, () => {
									if (document) {
										const method = !isMenuOpen ? 'add' : 'remove'
										document.body.classList[method]('menu-open')
									}
								})
							}}
							className="text-orange"
						>
							<span className="visuallyhidden">Menu</span>
						</Button>
					</header>
				</Headroom>

				<ReactModal
					onRequestClose={this.handleClose}
					overlayClassName="perf-fixed h-full w-full bg-black pin z-100"
					className="flex justify-center items-center"
					isOpen={isOpen}
					closeTimeoutMS={300}
					style={{
						content
					}}
				>
					<SearchModal handleClose={this.handleClose} />
				</ReactModal>
			</Fragment>
		)
	}
}
