import React from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'

const Icon = ({ icon, width, className = '', style = {} }) => {
	const { viewBox } = icon
	const [, , w, h] = R.split(' ')(viewBox)
	const aspect = `${(h / w) * 100}%`
	const size = typeof width !== 'undefined' ? width : `${w / 16}rem`

	return (
		<i
			className={`relative block max-w-full ${className}`}
			style={{ width: size, ...style }}
		>
			<span
				className="block w-full"
				style={{ paddingTop: aspect, height: 0 }}
			/>
			<svg className="absolute pin max-w-full h-full block fill-current">
				<use xlinkHref={`#${icon.id}`} />
			</svg>
		</i>
	)
}

/* eslint-disable react/require-default-props */

Icon.propTypes = {
	icon: PropTypes.object.isRequired,
	width: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.object
}

export default Icon
