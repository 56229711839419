import React from 'react' // eslint-disable-line no-unused-vars
import { Link } from 'gatsby'
import styled, { css } from 'react-emotion'
import { breakpoints } from '@/styles/helpers'

const navStyle = ({ isOpen }) => css`
	transform: ${isOpen ? 'scale(1);' : 'scale(1.5);'};
	transition: ${isOpen
		? 'opacity 300ms ease, transform 300ms ease, visibility 0ms 0ms'
		: 'opacity 300ms ease, transform 300ms ease, visibility 0ms 300ms'};
	visibility: ${isOpen ? 'visible' : 'hidden'};
	opacity: ${isOpen ? 1 : 0};

	${breakpoints.xl} {
		transform: scale(1);
		opacity: 1;
		visibility: visible;
	}
`

const Nav = styled('nav')(navStyle)

const hoverStyle = css`
	text-decoration: line-through;
	text-decoration-color: transparent;
	transition: text-decoration-color 450ms, color 450ms;

	&.is-current,
	&:hover {
		text-decoration-color: currentColor;
	}
`

const menu = [
	{ to: '/inspiration/', title: 'inspiration' },
	{ to: '/advice/', title: 'advice' },
	{ to: '/request-a-song/', title: 'request a song' },
	{ to: '/useful-organisations/', title: 'useful organisations' },
	{ to: '/contact/', title: 'contact us' }
]

export default ({ isOpen }) => (
	<Nav
		isOpen={isOpen}
		className="absolute mx-auto bg-black xl:bg-transparent px-1 py-4 flex flex-col items-center justify-center xl:static pin-t pin-r w-full xl:w-auto z-10 h-screen xl:h-auto xl:px-0 xl:py-0"
	>
		<ul className="list-reset text-ms-6 md:text-ms-15 xl:text-ms-3 text-center xl:flex">
			<li className="xl:hidden mb-1 xl:mb-0 xl:mr-1-5 max:mr-2-5 leading-none">
				<Link
					to="/"
					className={`${hoverStyle} hover:text-teal outline-none trans py-0-25 px-0-5 font-bold focus-box`}
					activeClassName="is-current"
				>
					home
				</Link>
			</li>
			{menu.map(({ to, title }) => (
				<li
					key={to}
					className="mb-1 xl:mb-0 xl:mr-0-5 max:mr-1-5 leading-none font-bold"
				>
					<Link
						to={to}
						className={`${hoverStyle} hover:text-teal outline-none trans py-0-25 lg:px-0-5 focus-box`}
						activeClassName="is-current text-teal"
					>
						{title}
					</Link>
				</li>
			))}
		</ul>
	</Nav>
)
