import React, { Component, createRef } from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import posed from 'react-pose'
import AudioBar from './AudioBar'

const SlideUp = posed.div({
	visible: { y: 0, delay: 250 },
	hidden: { y: '100%' }
})
class AudioBarContainer extends Component {
	constructor(props) {
		super(props)
		const { setPlayerInstance } = props
		// get hold of the player instance
		this.player = createRef()
		// add the instance to the global state object
		// we need access to this in other components
		setPlayerInstance(this.player)
	}

	state = {
		volume: 0.2,
		muted: false
	}

	// componentDidMount = () => {
	// 	const { showUi, togglePlay } = this.props
	// 	if (window && showUi) {
	// 		const player = this.player.current.player.getInternalPlayer()

	// 	}
	// }

	componentDidUpdate = prevProps => {
		if (!prevProps.track) return

		const {
			track: { id: prevId }
		} = prevProps
		const {
			track: { id },
			playing,
			setPlayState,
			reset
		} = this.props

		if (id === prevId) return

		if (playing) {
			setPlayState(true)
		} else {
			reset()
		}
	}

	playPause = () => {
		// get the togglePlay store method
		const { togglePlay } = this.props
		togglePlay()
	}

	stop = () => {
		// get the setPlayState store method
		const { setPlayState } = this.props
		setPlayState(false)
	}

	// todo
	setVolume = e => {
		const {
			target: { value }
		} = e

		this.setState(() => ({
			volume: parseFloat(value)
		}))
	}

	// todo
	toggleMuted = () => {
		const { muted } = this.state

		this.setState(() => ({
			muted: !muted
		}))
	}

	onPlay = () => {
		// get the setPlayState store method
		const { setPlayState, setInUse } = this.props
		setPlayState(true)
		setInUse(true)
	}

	onPause = () => {
		// get the setPlayState store method
		const { setPlayState } = this.props
		setPlayState(false)
	}

	onSeekMouseDown = () => {
		// get the setSeekingState store method
		const { setSeekingState } = this.props
		setSeekingState(false)
	}

	onSeekChange = e => {
		// get the value from range input
		const {
			target: { value }
		} = e

		// get the onSeekChange store method
		const { onSeekChange } = this.props

		onSeekChange(parseFloat(value))
	}

	onSeekMouseUp = e => {
		// get the value from range input
		const {
			target: { value }
		} = e

		// get the "player" instance from the store
		const { setSeekingState, playerInstance } = this.props
		setSeekingState(false)
		// call seekTo methond on the current instance
		playerInstance.current.seekTo(parseFloat(value))
	}

	onProgress = state => {
		// get the onProgress store method, and the current seeking state
		const { onProgress, seeking } = this.props

		// We only want to update time slider if we are not currently seeking
		if (!seeking) {
			onProgress(state)
		}
	}

	onDuration = duration => {
		// once we have a duration
		// get the onDuration method from the store
		const { onDuration } = this.props
		// add set the duration to the store
		onDuration(duration)
	}

	onReady = () => {
		const { setReady, setError } = this.props
		setReady(true)
		setError(false)
	}

	onError = e => {
		const { setReady, setError, setAudioBarVisibility } = this.props
		console.error('audio error', e) // eslint-disable-line no-console
		setReady(false)
		setError(true)
		setAudioBarVisibility(false)
	}

	onEnded = () => {
		// get the setPlayState store method
		const { setInUse } = this.props
		setInUse(false)
	}

	render() {
		const { track, playing, showUi } = this.props
		const { volume, muted } = this.state

		const mediaSource = track ? track.mediaSource : null

		return (
			<SlideUp
				style={{ height: 120 }}
				className="perf-fixed w-full pin-b overflow-hidden z-100"
				pose={showUi ? 'visible' : 'hidden'}
			>
				<ReactPlayer
					ref={this.player}
					width="100%"
					height="120px"
					url={mediaSource}
					playing={playing}
					volume={volume}
					muted={muted}
					controls={false}
					onReady={this.onReady}
					onPlay={this.onPlay}
					onPause={this.onPause}
					onError={this.onError}
					onProgress={this.onProgress}
					onDuration={this.onDuration}
					onEnded={this.onEnded}
				/>
			</SlideUp>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: {
		togglePlay,
		stop,
		setVolume,
		toggleMuted,
		setPlayState,
		onDuration,
		onProgress,
		onSeekChange,
		setSeekingState,
		setPlayerInstance,
		reset,
		setError,
		setReady,
		setAudioBarVisibility,
		setInUse
	}
}) => ({
	togglePlay,
	stop,
	setVolume,
	toggleMuted,
	setPlayState,
	onDuration,
	onProgress,
	onSeekChange,
	setSeekingState,
	setPlayerInstance,
	reset,
	setError,
	setReady,
	setAudioBarVisibility,
	setInUse
})

export default connect(
	mapState,
	mapDispatch
)(AudioBarContainer)

// <AudioBar {...this.props } />
