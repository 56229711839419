import React from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'

/**
 * @function Icon
 * @description Wrap an svg element with an aspect ratio container (like we do in mud-fractal projects)
 * @example
 *
 * import facebookIcon from '../icons/ui-facebook.svg'
 * import Icon from '@/components/utils/Icon'
 *
 * const Comp = () => <Icon icon={facebookIcon} />
 *
 * @param {Object} icon - the svg icon to use
 * @param {String} width - an optional width to use
 * @param {String} className - an optional className to apply to the wrapper
 * @param {Object} style - an optional style object to apply to the wrapper
 *
 * @return {Component}
 */

const BetterIcon = ({ icon, className = '', style = {} }) => {
	const { viewBox } = icon
	const [, , w, h] = R.split(' ')(viewBox)
	const aspect = `${(h / w) * 100}%`

	return (
		<i className={`${className} icon relative block max-w-full`} style={style}>
			<span
				className="block w-full"
				style={{ paddingTop: aspect, height: 0 }}
			/>
			<svg className="absolute pin w-full h-full block fill-current">
				<use xlinkHref={`#${icon.id}`} />
			</svg>
		</i>
	)
}

/* eslint-disable react/require-default-props */

BetterIcon.propTypes = {
	icon: PropTypes.object.isRequired,
	className: PropTypes.string,
	style: PropTypes.object
}

export default BetterIcon
