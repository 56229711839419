export const player = {
	state: {
		playing: false,
		volume: 0.2,
		muted: false,
		played: 0,
		playedSeconds: 0,
		seeking: false,
		duration: 0,
		showUi: false,
		track: null,
		next: null,
		prev: null,
		playerInstance: null,
		pagetype: null,
		playlist: null,
		queuedPlayList: null,
		currentId: 0,
		shouldVideoPlay: false,
		shouldAudioAutoPlay: false,
		hasError: false,
		isReady: false,
		inUse: false
	},

	reducers: {
		setInUse(state, inUse) {
			return {
				...state,
				inUse
			}
		},

		setError(state, hasError) {
			return {
				...state,
				hasError
			}
		},

		setReady(state, isReady) {
			return {
				...state,
				isReady
			}
		},

		setTrack(state, track) {
			return {
				...state,
				track
			}
		},

		setVideoPlayState(state, shouldVideoPlay) {
			return {
				...state,
				shouldVideoPlay
			}
		},

		setAudioPlayState(state, shouldAudioAutoPlay) {
			return {
				...state,
				shouldAudioAutoPlay
			}
		},

		reset(state) {
			return {
				...state,
				playing: false,
				played: false,
				playedSeconds: false,
				duration: 0
			}
		},

		setCurrentPageId(state, currentId) {
			return {
				...state,
				currentId
			}
		},

		setPageType(state, pagetype) {
			return {
				...state,
				pagetype
			}
		},

		setPlayerInstance(state, playerInstance) {
			return {
				...state,
				playerInstance
			}
		},

		setAudioBarVisibility(state, showUi) {
			return {
				...state,
				showUi
			}
		},

		setSeekingState(state, seeking) {
			return {
				...state,
				seeking
			}
		},

		onSeekChange(state, played) {
			return {
				...state,
				played
			}
		},

		togglePlay(state) {
			const { playing } = state
			return {
				...state,
				playing: !playing
			}
		},

		setPlayState(state, playing) {
			return {
				...state,
				playing
			}
		},

		stop(state) {
			return {
				...state,
				playing: false
			}
		},

		setVolume(state, volume) {
			return {
				...state,
				volume: parseFloat(volume)
			}
		},

		toggleMuted(state) {
			const { muted } = state
			return {
				...state,
				muted: !muted
			}
		},

		onProgress(state, { played, playedSeconds }) {
			const { seeking } = state
			// We only want to update time slider if we are not currently seeking
			if (!seeking) {
				return {
					...state,
					played,
					playedSeconds
				}
			}

			return state
		},

		onDuration(state, duration) {
			return {
				...state,
				duration
			}
		}
	}
}
