import React from 'react' // eslint-disable-line no-unused-vars
import styled, { css } from 'react-emotion'
import { px2em } from '@/styles/settings/tailwind.utils'

const btn = css`
	transition: opacity 300ms ease, transform 300ms ease;
`

const btnContainer = css`
	width: ${px2em(50)};
	height: ${px2em(50)};
	transition: opacity 300ms ease, transform 300ms ease;
`

const btnSlice = css`
	top: 50%;
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: ${px2em(10)};
	transform-origin: 50%;
	transition: opacity 300ms ease, transform 300ms ease;
	height: ${px2em(3)};
	background-color: currentColor;
`

const btnTop = ({ isOpen }) =>
	css`
		width: ${px2em(25)};
		transform: ${isOpen
		? 'translate3d(0, calc(-50%), 0) rotate(-45deg);'
		: 'translate3d(0, calc(-50% - 8px), 0)'};
	`
const btnMiddle = ({ isOpen }) =>
	css`
		width: ${px2em(20)};
		transform: translate3d(0, -50%, 0);
		opacity: ${isOpen ? 0 : 1};
	`

const btnBottom = ({ isOpen }) =>
	css`
		width: ${isOpen ? px2em(25) : px2em(15)};
		transform: ${isOpen
		? 'translate3d(0, calc(-50%), 0) rotate(45deg);'
		: 'translate3d(0, calc(-50% + 8px), 0)'};
	`
const Button = styled('a')(btn)
const ButtonWrap = styled('span')(btnContainer)
const TopBun = styled('span')(btnTop)
const Meat = styled('span')(btnMiddle)
const BottomBun = styled('span')(btnBottom)

export default ({ isOpen, onClick }) => (
	<Button
		href="#0"
		isOpen={isOpen}
		onClick={onClick}
		style={{ fontSize: 18 }}
		className="relative flex items-center justify-center z-50 xl:hidden"
	>
		<span className="visuallyhidden">{isOpen ? 'close' : 'menu'}</span>

		<ButtonWrap className="relative">
			<TopBun isOpen={isOpen} className={`${btnSlice} absolute`} />
			<Meat isOpen={isOpen} className={`${btnSlice} absolute`} />
			<BottomBun isOpen={isOpen} className={`${btnSlice} absolute`} />
		</ButtonWrap>
	</Button>
)
