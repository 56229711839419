import dayjs from 'dayjs'
import * as R from 'ramda'
import matchSorter from 'match-sorter'

export const advancedFilter = (theItems, value) =>
	matchSorter(theItems, value, {
		keys: ['value']
	})

export const addLeadingSlash = uri => `/${uri}`

export const printProps = props => JSON.stringify(props, null, 2)

export const flattenImageProp = R.map(item => {
	if (item.image && item.image.length > 0) {
		const { title, optimisedImage, optimisedBanner } = item.image[0]
		return {
			...item,
			image: { ...optimisedImage, title },
			banner: { ...optimisedBanner, title }
		}
	}
	return item
})

export const extractContentFromBody = R.map(item => {
	if (item.body) {
		return {
			...item,
			body: item.body.content
		}
	}

	return item
})

export const convertTimeStampToDate = R.map(item => {
	if (item.postDate) {
		return {
			...item,
			rawDate: item.postDate * 1000,
			postDate: dayjs(item.postDate * 1000).format('DD.MM.YY')
		}
	}
	return item
})

export const convertType = R.map(item => {
	if (item.__typename) {
		return {
			...item,
			type: item.__typename.split('Craft_')[1].toLowerCase()
		}
	}

	return item
})

export const setSlug = R.map(item => {
	if (item.uri) {
		return {
			...item,
			uri: `/${item.uri}`
		}
	}

	return item
})

export const prepItem = R.compose(
	extractContentFromBody,
	convertTimeStampToDate,
	convertType,
	setSlug,
	flattenImageProp
)

export const prepSearchResult = R.compose(
	convertTimeStampToDate,
	setSlug,
	flattenImageProp,
	R.map(item => {
		const body = item.contentBlocks
			? R.compose(
					R.join(' '),
					R.pluck('content'),
					R.pluck('body')
			  )(item.contentBlocks)
			: item.__typename === 'Directory'
			? item.address
			: ''

		return {
			...item,
			body,
			type: item.__typename.toLowerCase()
		}
	})
)

export const getSingleFromArray = R.compose(
	R.head,
	prepItem,
	R.prop('entries')
)

export const pluckPrepItem = R.compose(
	R.head,
	prepItem
)

export const prepDirectoryItem = R.compose(
	prepItem,
	R.uniqBy(item => item.id),
	R.flatten,
	R.filter(R.length),
	R.pluck('entries'),
	R.pluck('relatedEntries'),
	R.prop('edges')
)

export const prepDirectoryFilterResult = R.compose(
	prepItem,
	R.uniqBy(item => item.id),
	R.flatten,
	R.filter(R.length),
	R.pluck('entries'),
	R.pluck('relatedEntries'),
	R.prop('edges'),
	R.prop('tagsConnection')
)

export const prepRelatedContent = R.compose(
	prepItem,
	R.uniqBy(item => item.id),
	R.flatten,
	R.pluck('entries'),
	R.pluck('relatedEntries'),
	R.prop('edges')
)

export const debounce = (fn, time) => {
	let timeoutId

	function wrapper(...args) {
		if (timeoutId) {
			clearTimeout(timeoutId)
		}
		timeoutId = setTimeout(() => {
			timeoutId = null
			fn(...args)
		}, time)
	}

	return wrapper
}

export const noop = () => {}

export const getPrevFromArray = (index, items) =>
	items[index - 1] ? items[index - 1] : items[items.length - 1]

export const getNextFromArray = (index, items) =>
	items[index + 1] ? items[index + 1] : items[0]

export const convertTimeStringToSeconds = duration => {
	if (!duration) return 234567890

	const [hours, minutes, seconds] = R.compose(
		R.map(val => parseInt(val, 10)),
		R.split(':')
	)(duration) // ?

	return hours * 3600 + minutes * 60 + seconds
}

export const prepSeriesContent = tmpSeries => {
	const series = tmpSeries
		? R.compose(
				R.head,
				R.prop('categories'),
				R.prop('relatedCategories'),
				R.head
		  )(tmpSeries)
		: null

	const episodes = series
		? R.compose(
				prepItem,
				R.prop('episodes')
		  )(series)
		: null

	return { series, episodes }
}

const flattenTagNames = R.map(item => {
	const { tags } = item

	return {
		tagNames: R.compose(
			R.join(' '),
			R.map(tags => tags.title)
		)(tags),
		...item
	}
})

const markScore = word => {
	const regex = new RegExp(`(^|\\s)${word}(\\s|$)`, 'gmi')

	return R.map(item => {
		const { tagNames, title } = item
		const titleMatch = title.match(regex)
		// const bodyMatch = body.match(regex)
		const tagMatch = tagNames.match(regex)
		let score = 0

		if (titleMatch) {
			score += 2000 * titleMatch.length
		} else if (title.includes(word)) {
			score += 800
		}

		if (tagMatch) {
			score += 1000 * tagMatch.length
		} else if (tagNames.includes(word)) {
			score += 400
		}

		return {
			...item,
			score
		}
	})
}

const resultCategories = R.compose(
	R.map(([key, value]) => ({
		key,
		value,
		length: R.length(value)
	})),
	Object.entries,
	R.groupBy(item => item.__typename)
)

export const rankResults = R.curry((data, query) => {
	const all = R.compose(
		R.reverse,
		R.sortBy(a => a.score),
		R.filter(item => item.score),
		markScore(query),
		flattenTagNames,
		prepSearchResult
	)(data)

	return {
		all,
		categories: resultCategories(all)
	}
})

export const assignIconsToTags = (icons, tagsWeWant) =>
	R.compose(
		R.map(({ title, ...rest }) => ({
			...rest,
			title,
			icon: icons[title],
			uri: `/tags/${title}/`
		})),
		R.filter(({ title }) => tagsWeWant.includes(title))
	)

const convertImage = R.compose(
	R.map(([key, value]) => `${value} ${key}w`),
	R.reverse
)

const getSrc = R.compose(
	R.head,
	R.reverse
)

export const prepDataFromSuggestions = R.map(item => {
	const {
		type,
		uri,
		image: {
			optimizedImageUrls,
			optimizedWebPImageUrls,
			colorPalette,
			placeholderWidth,
			placeholderHeight
		}
	} = item

	const srcset = R.compose(
		R.join(','),
		convertImage
	)(Object.entries(optimizedImageUrls))

	const srcsetWebp = R.compose(
		R.join(','),
		convertImage
	)(Object.entries(optimizedWebPImageUrls))

	return {
		...item,
		uri: `/${uri}`,
		type: type.toLowerCase(),
		image: {
			src: getSrc(Object.values(optimizedImageUrls)),
			srcWebp: getSrc(Object.values(optimizedWebPImageUrls)),
			colorPalette,
			srcset,
			srcsetWebp,
			placeholderWidth,
			placeholderHeight
		}
	}
})

export const createPaginationLinks = ({ index, pageCount, pathPrefix }) => ({
	previousUrl: index - 1 === 1 ? '' : (index - 1).toString(),
	nextUrl: (index + 1).toString(),
	pages: Array.from({ length: pageCount }).map((_, i) => {
		const number = i + 1
		return {
			page: number,
			active: index === number,
			uri: i > 0 ? `${pathPrefix}/${number}` : pathPrefix
		}
	})
})

export const getSeoImage = entry => {
	const { socialMetaImage, image } = entry
	return socialMetaImage && socialMetaImage.length
		? ((socialMetaImage[0] || {}).optimisedImage || {}).src
		: image.length
		? ((image[0] || {}).optimisedImage || {}).src
		: null
}
