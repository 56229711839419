import { navigate } from 'gatsby'
import client from '@/utils/client'
import { rankResults } from '@/utils/'

export default ({ searchQuery, searchTerm }) =>
	client
		.request(searchQuery, {
			term: `'${searchTerm}'`
		})
		.then(({ entries }) => {
			const results = rankResults(entries, searchTerm)
			navigate('/search/', {
				state: { ...results, searchTerm, count: results.all.length }
			})
		})
