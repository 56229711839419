import { Component } from 'react'
import jsonp from 'jsonp'
import toQueryString from 'to-querystring'
import validate from 'validate.js'

export default class Subscribe extends Component {
	state = {
		status: '',
		message: ''
	}

	handleSubmit = data => {
		const { url } = this.props
		const params = toQueryString(data)

		const constraints = {
			email: {
				email: true
			}
		}

		if (validate({ email: data.EMAIL }, constraints)) {
			this.setState({
				status: 'error',
				message: 'Please enter a valid email address'
			})

			return
		}

		this.setState(
			{
				status: 'loading',
				message: ''
			},
			() => {
				jsonp(`${url}&${params}`, { param: 'c' }, (err, data) => {
					if (err) {
						this.setState({
							status: 'error',
							message: err
						})

						return
					}

					this.setState({
						status: data.result === 'success' ? 'success' : 'error',
						message: data.msg
					})
				})
			}
		)
	}

	render() {
		const { children } = this.props
		return children({ ...this.state, handleSubmit: this.handleSubmit })
	}
}
