import React, { Component } from 'react'
// import { Link } from 'gatsby'
import ProgressBar from '@/components/ProgressBar/ProgressBar'
import Duration from '@/components/utils/Duration'
import BetterIcon from '@/components/utils/BetterIcon'
import playIcon from '@/icons/ui-play.svg'
import pauseIcon from '@/icons/ui-pause.svg'
// import rewindIcon from '@/icons/ui-rewind.svg'
// import forwardIcon from '@/icons/ui-forward.svg'
import { convertTimeStringToSeconds } from '@/utils'

import posed from 'react-pose'

const SlideUp = posed.div({
	visible: { y: 0, delay: 250 },
	hidden: { y: '100%' }
})

export default class AudioBar extends Component {
	render() {
		const {
			playing,
			togglePlay,
			duration,
			played,
			onSeekMouseDown,
			onSeekChange,
			onSeekMouseUp,
			loaded,
			track,
			showUi,
			inUse
		} = this.props

		const title = track ? track.title : ''
		const initalDuration = track ? track.duration : ''

		// what icon should we display
		const playStateIcon = !playing ? playIcon : pauseIcon
		const currentTime = duration * played

		// pagetype ?
		// if we are on an audio page, clicking prev/next should update
		// the current page, and also change the current track, if we
		// are not on an audio page, on click we should only change the
		// current track, not the current page

		return (
			<SlideUp
				className="text-black flex flex-row-reverse lg:flex-row y-100 audio-bar"
				pose={showUi && inUse ? 'visible' : 'hidden'}
			>
				<div className="bg-grey-lightest md:py-0-75 p-0-5 md:px-1-5 flex-no-shrink flex items-center">
					<button
						onClick={togglePlay}
						type="button"
						tabIndex={showUi ? '' : '-1'}
						aria-label="Play"
						className="w-50 h-50 flex items-center justify-center text-black border-3 border-orange rounded-full bg-white circle-animate-wrap circle-animate trans"
					>
						<BetterIcon icon={playStateIcon} className="m-auto h-15 w-full" />
						<span className="visuallyhidden">
							{!playing ? 'Play' : 'Pause'}
						</span>
					</button>
				</div>
				<div className="bg-white p-0-5 md:py-1 md:px-1-5 w-full flex flex-wrap items-center justify-start md:justify-between">
					<div className="flex md:w-full flex-wrap md:justify-between text-ms--2">
						<p className="hidden md:block">{title}</p>
						<div className="text-ms--3">
							<Duration seconds={duration * played} />{' '}
							<span className="mr-0-25 ml-0-25 text-grey-light">/</span>
							<Duration
								seconds={duration || convertTimeStringToSeconds(initalDuration)}
							/>
						</div>
					</div>

					<div className="mt-0-75 w-full">
						<ProgressBar
							tabIndex={showUi ? '' : '-1'}
							onMouseDown={onSeekMouseDown}
							onMouseUp={onSeekMouseUp}
							onChange={onSeekChange}
							value={((duration * played) / duration) * 100 || 0}
							currentTime={currentTime}
							loaded={loaded || 0}
							id="audio-bar"
							className="text-grey-lightest"
						/>
					</div>
				</div>
			</SlideUp>
		)
	}
}

/*

const ButtonInards = ({ icon, label }) => (
	<Fragment>
		<BetterIcon icon={icon} className="w-30" />
		<span className="visuallyhidden">{label}</span>
	</Fragment>
)

const NextPrevButton = ({
	pagetype,
	to,
	onClick,
	label,
	icon,
	direction,
	tabIndex
}) =>
	pagetype ? (
		<Link
			to={`/${to.uri}`}
			tabIndex={tabIndex}
			aria-label={label}
			className="text-black hover:opacity-75 focus:text-orange trans hidden md:block outline-none"
			onClick={() => onClick(direction)}
		>
			<ButtonInards icon={icon} label={label} />
		</Link>
	) : (
		<button
			type="button"
			tabIndex={tabIndex}
			onClick={() => onClick(direction)}
			aria-label={label}
			className="text-black hover:opacity-75 focus:text-orange trans hidden md:block outline-none"
		>
			<ButtonInards icon={icon} label={label} />
		</button>
	)
	
<NextPrevButton
	to={prev}
	tabIndex={showUi ? '' : '-1'}
	pagetype={pagetype}
	label="previous track"
	icon={rewindIcon}
	onClick={this.goTo}
	direction="prev"
/> 

<NextPrevButton
	to={next}
	tabIndex={showUi ? '' : '-1'}
	pagetype={pagetype}
	label="next track"
	icon={forwardIcon}
	onClick={this.goTo}
	direction="next"
/> 
*/
