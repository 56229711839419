import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Duration from '@/components/utils/Duration'
export default class ProgressBar extends Component {
	static defaultProps = {
		className: ''
	}

	render() {
		const {
			value,
			onChange,
			currentTime,
			onMouseDown,
			onMouseUp,
			loaded,
			tabIndex,
			className = 'text-tundora',
			id
		} = this.props

		return (
			<div className={`c-progress group h-full relative ${className}`}>
				<progress className="absolute w-full pin" max="1" value={loaded} />
				<label htmlFor={`progress-${id}`}>
					<span className="visuallyhidden">progress</span>
					<input
						className="outline-none focus-border"
						tabIndex={tabIndex}
						type="range"
						name={`progress-${id}`}
						id={`progress-${id}`}
						min={0}
						max={1}
						step="any"
						onMouseDown={onMouseDown}
						onMouseUp={onMouseUp}
						onChange={onChange}
						value={value / 100}
						style={{
							'--value': `${value}%`,
							height: '6px'
						}}
					/>
				</label>

				<Duration
					seconds={currentTime}
					className="c-player__tooltip opacity-0 group-hover:opacity-100 trans trans-opacity p-0-25 bg-white absolute pin-b pointer-events-none mb-0-75 text-ms--4 whitespace-no-wrap text-black"
					style={{ '--value': `${value}%` }}
				/>
			</div>
		)
	}
}

ProgressBar.propTypes = {
	value: PropTypes.number.isRequired,
	loaded: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onMouseDown: PropTypes.func.isRequired,
	onMouseUp: PropTypes.func.isRequired,
	currentTime: PropTypes.number.isRequired,
	className: PropTypes.string
}
