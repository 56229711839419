module.exports = [{
      plugin: require('/data/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/data/src/wrapper/layout.js"},
    },{
      plugin: require('/data/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#23BCB7","showSpinner":true},
    },{
      plugin: require('/data/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[],"autoLabel":true},
    },{
      plugin: require('/data/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
