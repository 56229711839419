import React, { Component, createRef } from 'react'
import Button from '@/components/Button/Button'
import closeIcon from '@/icons/ui-close.svg'
import Icon from '@/components/utils/Icon'
import Subscribe from '@/components/utils/Subscribe'

export default class Signup extends Component {
	state = {
		email: '',
		name: '',
		showErrors: true
	}

	nameInputField = createRef()

	// focus on the form when the modal opens
	componentDidMount = () => {
		this.nameInputField.current.focus()
	}

	render() {
		const { handleClose } = this.props
		const { email, name, showErrors } = this.state

		return (
			<div className="shadow max-w-1500 w-full bg-white text-black p-1 sm:p-2 relative mx-auto">
				<div className="mt-1-75 sm:w-20/24 lg:w-22/24 lg:flex">
					<h4
						className="mb-1 text-h6-fluid font-bold lg:w-11/24"
						id="signup-title"
					>
						keep me posted with{' '}
						<span className="text-orange">straightline news</span>
					</h4>
					<Subscribe url="https://prisonradioassociation.us9.list-manage.com/subscribe/post-json?u=33932ee491848aaa8baef7370&id=d5e308def0">
						{({ handleSubmit, status, message }) => (
							<form
								id="signup-form"
								className="text-xs-fluid lg:w-14/24"
								action="https://prisonradioassociation.us9.list-manage.com/subscribe/post?u=33932ee491848aaa8baef7370&id=d5e308def0"
								method="post"
								noValidate
								onSubmit={e => {
									e.preventDefault()

									handleSubmit({ EMAIL: email, NAME: name })
									this.setState({ showErrors: true })
								}}
							>
								<div className="md:flex md:-m-0-5">
									<label
										htmlFor="your_name"
										className="mb-1 md:w-12/24 md:p-0-75"
									>
										<span className="font-bold mb-0-5 block">your name</span>
										<input
											ref={this.nameInputField}
											className="max-w-sm text-black w-full bg-grey-lightest w-full px-0-75 py-0-5"
											type="text"
											id="your_name"
											name="your_name"
											value={name}
											onChange={e => {
												this.setState({
													name: e.target.value,
													showErrors: false
												})
											}}
										/>
									</label>

									<label htmlFor="email" className="mb-1 md:w-12/24 md:p-0-75">
										<span className="font-bold mb-0-5 block">
											email address
										</span>
										<input
											className="max-w-sm text-black w-full bg-grey-lightest w-full px-0-75 py-0-5"
											type="email"
											id="email"
											name="email"
											value={email}
											onChange={e => {
												this.setState({
													email: e.target.value,
													showErrors: false
												})
											}}
										/>
									</label>
								</div>

								<Button
									type="submit"
									position="center"
									bg={['orange', 'teal']}
									rounded={false}
								>
									<span>
										{status === 'loading'
											? 'sending'
											: status === 'success'
												? 'sent'
												: 'submit'}
									</span>
								</Button>
								{showErrors &&
									message && (
									<div
										className={`${
											status === 'error' ? 'text-error' : 'text-teal'
										} text-xs-fluid mt-2`}
									>
										{message}
									</div>
								)}
							</form>
						)}
					</Subscribe>
				</div>
				<button
					onClick={e => {
						e.preventDefault()
						handleClose()
					}}
					type="button"
					className="text-black absolute flex items-center pin-t pin-r mt-0-5 mr-0-5 p-0-5"
				>
					<span className="mr-0-25">close</span>
					<Icon icon={closeIcon} className="w-20" />
				</button>
			</div>
		)
	}
}
