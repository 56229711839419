import React, { Component, createRef } from 'react'
import { generateSkyLine, makeCity } from '@/utils/skyline-generator'
import { tween } from 'popmotion'
import raf from 'raf-throttle'

let skyline = makeCity(generateSkyLine())

export default class FooterCityScape extends Component {
	raf = undefined

	timer = undefined

	canvas = createRef()

	handle = undefined

	componentDidMount = () => {
		if (window) {
			this.canvasCtx = this.canvas.current.getContext('2d')
			this.canvas.current.width = window.innerWidth
			this.canvas.current.height = 100
			this.handle = raf(this.onResize)

			window.addEventListener('resize', this.handle, false)
		}
	}

	componentDidUpdate = prevProps => {
		const { inView: prevInView } = prevProps
		const { inView } = this.props

		if (prevInView === inView) return

		if (inView) {
			this.draw()
		} else {
			clearTimeout(this.timer)
		}
	}

	componentWillUnmount = () => {
		if (window) {
			clearTimeout(this.timer)

			window.removeEventListener('resize', this.handle, false)
			this.handle.cancel()
		}
	}

	draw = () => {
		this.canvasCtx.globalAlpha = 0.25

		const newSkyLine = makeCity(generateSkyLine())

		newSkyLine.forEach((item, index) => {
			tween({
				from: {
					x: skyline[index].x,
					y: skyline[index].y,
					height: skyline[index].height
				},
				to: {
					...item
				},
				duration: 1000
			}).start({
				update: ({ x, y, height }) => {
					this.canvasCtx.clearRect(x, 0, 4, 100)
					this.canvasCtx.fillStyle = 'rgb(211, 211, 211)'
					this.canvasCtx.fillRect(x, y, 4, height)
				}
			})
		})

		this.timer = setTimeout(() => {
			const { inView } = this.props
			skyline = newSkyLine
			if (inView) {
				this.draw()
			}
		}, 5000)
	}

	onResize = () => {
		this.canvas.current.width = window.innerWidth
		this.canvasCtx.clearRect(0, 0, window.innerWidth, 100)
	}

	render() {
		return <canvas ref={this.canvas} className="block w-full h-100" />
	}
}
