import React from 'react'

const pad = string => `0${string}`.slice(-2)

const format = seconds => {
	const date = new Date(seconds * 1000)
	const hh = date.getUTCHours()
	const mm = date.getUTCMinutes()
	const ss = pad(date.getUTCSeconds())
	if (hh) {
		return `${pad(hh)}:${pad(mm)}:${ss}`
	}
	return `${pad(mm)}:${ss}`
}

export default function Duration({
	className,
	seconds,
	style = {},
	convertFormat = true
}) {
	return (
		<time
			dateTime={`P${Math.round(seconds)}S`}
			style={style}
			className={className}
		>
			{convertFormat ? format(seconds) : seconds}
		</time>
	)
}
