import React, { Component, createRef } from 'react'
import closeIcon from '@/icons/ui-close.svg'
import BetterIcon from '@/components/utils/BetterIcon'
import searchQuery from '@/queries/search'
import searchIcon from '@/icons/ui-search.svg'
import searchApi from '@/utils/search'

export default class SearchModal extends Component {
	searchInputField = createRef()

	state = {
		searchTerm: ''
	}

	onSubmit = e => {
		e.preventDefault()

		const { handleClose } = this.props
		const { searchTerm } = this.state

		searchApi({ searchQuery, searchTerm }).then(() => {
			handleClose()
		})
	}

	onChange = e => {
		const {
			target: { value: searchTerm }
		} = e

		this.setState(() => ({ searchTerm }))
	}

	// focus on the form when the modal opens
	componentDidMount = () => {
		this.searchInputField.current.focus()
	}

	render() {
		const { handleClose } = this.props
		const { searchTerm } = this.state

		return (
			<div>
				<form onSubmit={this.onSubmit} className="relative">
					<label htmlFor="search">
						<input
							type="search"
							name="search"
							id="search"
							ref={this.searchInputField}
							className="appearance-none focus:border-teal text-white bg-black text-h1-fluid border-b-4 border-white px-0-25 py-0-5"
							value={searchTerm}
							onChange={this.onChange}
							placeholder="search"
						/>
					</label>

					<button
						type="submit"
						className="absolute pin-y pin-r block hover:text-orange appearance-none p-0-5"
					>
						<BetterIcon icon={searchIcon} className="w-30" />
						<span className="visuallyhidden">search</span>
					</button>
				</form>
				<button
					onClick={e => {
						e.preventDefault()
						handleClose()
					}}
					type="button"
					className="text-white absolute hover:text-orange flex items-center pin-t pin-r mt-1 mr-1 p-0-5"
				>
					<span className="mr-0-5 text-xs-fluid">close</span>
					<BetterIcon icon={closeIcon} className="w-20" />
				</button>
			</div>
		)
	}
}
