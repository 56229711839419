export default key => {
	const day = 86400000
	const wait = day * 14
	const now = Date.now()
	const name = `__westraightline____${key}__`

	const store = localStorage.getItem(name)
	if (store) {
		const showPopup = now > parseInt(store, 10)

		if (showPopup) {
			localStorage.setItem(name, now + wait)
		}

		return showPopup
	}

	localStorage.setItem(name, now + wait)

	return true
}
