/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component, Fragment } from 'react'
import Observer from 'react-intersection-observer'
import ReactModal from 'react-modal'
import siteLogo from '@/icons/site-logo.svg'
import { Link } from 'gatsby'
import Icon from '@/components/utils/Icon'
import once from 'lodash.once'
import FooterCityScape from './FooterCityScape'
import Signup from '@/components/Signup/Signup'
import hasUserVisitedRecently from '@/utils/localStorage'

ReactModal.setAppElement('#___gatsby')

const overlay = {
	background: 'none',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-end'
}

const content = {
	position: 'relative',
	top: 'auto',
	left: 'auto',
	right: 'auto',
	bottom: 'auto',
	border: 0,
	background: 'none',
	overflow: 'auto',
	borderRadius: 0,
	outline: 'none',
	padding: '0 0 1.25rem 0',
	width: '90%'
}

const shouldModalBeOpened = once(hasUserVisitedRecently)

export default class Footer extends Component {
	state = {
		isOpen: false,
		shouldOpen: true
	}

	onChange = inview => {
		const showPopup = shouldModalBeOpened('signup')
		const { shouldOpen } = this.state

		if (inview && showPopup && shouldOpen) {
			this.setState(() => ({
				isOpen: true,
				shouldOpen: false
			}))
		}
	}

	handleClose = () => {
		this.setState(() => ({
			isOpen: false
		}))
	}

	render() {
		const { isOpen } = this.state

		return (
			<Observer onChange={this.onChange}>
				{({ ref, inView }) => (
					<Fragment>
						<footer
							ref={ref}
							className="bg-black w-full border-t border-grey-brd mt-auto"
						>
							<div className="p-1 md:px-2 lg:px-3 lg:pt-2-5 sm:flex sm:justify-between">
								<div className="sm:mr-2 text-xs-fluid">
									<div className="text-white mb-0-5">
										<Icon icon={siteLogo} width="113px" />
									</div>
									<ul className="list-reset lg:flex">
										<li className="mb-0-5 lg:mb-0 lg:mr-1">
											<Link
												className="hover:text-orange focus:text-orange outline-none"
												to="/terms-and-conditions/"
											>
												Terms & conditions
											</Link>
										</li>
										<li className="mb-0-5 lg:mb-0">
											<Link
												className="hover:text-orange focus:text-orange outline-none"
												to="/privacy/"
											>
												Privacy
											</Link>
										</li>
									</ul>
								</div>
								<div>
									<p className="text-grey-light lg:text-right text-xxs-fluid leading-normal">
										&copy;️ 2018 Prison Radio Association <br />
										Registered Charity in England & Wales 1114760
									</p>
								</div>
							</div>
							<div className="relative">
								<FooterCityScape inView={inView} />
							</div>
						</footer>

						<ReactModal
							onRequestClose={this.handleClose}
							className="mx-auto trans opacity-0 y-100 trans-slow signup-modal"
							isOpen={isOpen}
							closeTimeoutMS={300}
							aria={{
								labelledby: 'signup-title',
								describedby: 'signup-form'
							}}
							style={{
								overlay,
								content
							}}
						>
							<Signup isOpen={isOpen} handleClose={this.handleClose} />
						</ReactModal>
					</Fragment>
				)}
			</Observer>
		)
	}
}
