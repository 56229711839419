import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import '@/styles/style.css'
import posed, { PoseGroup } from 'react-pose'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

// https://github.com/gatsbyjs/gatsby/issues/7209#issuecomment-415807021

const MobileContainer = posed.div({
	entering: {
		x: '100%',
		opacity: 0
	},
	enter: {
		opacity: 1,
		x: 0,
		transition: {
			opacity: { ease: 'easeOut', duration: 300 },
			x: { ease: 'easeIn', duration: 300 }
		}
	},
	exit: {
		opacity: 0,
		delay: 0,
		transition: {
			opacity: { ease: 'easeOut', duration: 150 }
		},
		onComplete: () => {
			if (window) {
				window.scrollTo(0, 0)
			}
		}
	}
})

const DesktopContainer = posed.div({
	entering: {
		opacity: 0
	},
	enter: {
		opacity: 1,
		transition: {
			opacity: { ease: 'easeOut', duration: 300 }
		}
	},
	exit: {
		opacity: 0,
		delay: 0,
		transition: {
			opacity: { ease: 'easeOut', duration: 150 }
		},
		onComplete: () => {
			if (window) {
				window.scrollTo(0, 0)
			}
		}
	}
})

const PoseWrapper = ({ children }) => (
	<PoseGroup preEnterPose="entering">{children}</PoseGroup>
)

const SSR = ({ children }) => <div>{children}</div>

export default class Wrapper extends Component {
	state = {
		Container: SSR,
		Animation: SSR
	}

	componentDidMount = () => {
		if (window) {
			const Animation =
				window.innerWidth > 768 ? DesktopContainer : MobileContainer

			this.setState({
				Container: PoseWrapper,
				Animation
			})
		}
	}

	render() {
		const {
			children,
			location,
			darkTheme = true,
			pageContext: { type }
		} = this.props
		const { Animation, Container } = this.state
		const dark = type === 'blog'

		return (
			<Fragment>
				<Header dark={dark} location={location.href} />
				<div className="min-h-screen flex flex-col">
					<Container>
						<Animation key={location.href}>
							<main
								className={`w-full flex-1 bg-${
									darkTheme ? 'black' : 'white'
								} lg:pt-4 xl:pt-5`}
							>
								{children}
							</main>
						</Animation>
					</Container>
					<Footer />
				</div>
			</Fragment>
		)
	}
}

Wrapper.propTypes = {
	children: PropTypes.node.isRequired
}
