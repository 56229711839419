import * as R from 'ramda'
import shuffle from 'shuffle-array'
const rand = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

const mapIndexed = R.addIndex(R.map)
const reducedIndexed = R.addIndex(R.reduce)

const makeBuildings = length =>
	R.map(() => {
		const height = rand(24, 100)
		return R.map(() => height)(Array.from({ length: rand(2, 9) }))
	})(Array.from({ length }))

const makeChurch = length =>
	R.map(() => {
		const base = rand(20, 40)
		const width = rand(3, 7)
		const height = rand(base + width, 100)
		const isEven = width % 2 === 0

		return reducedIndexed((acc, curr, index, array) => {
			const num = index + 1
			const isTop = isEven
				? width / 2 === num || width / 2 + 1 === num
				: Math.ceil(width / 2) === num
			const down = index > width / 2
			const prev = acc[index - 1]
			const delta = array.length - index

			if (isTop) {
				acc.push(height)
				return acc
			}

			if (index === 0 || index === array.length - 1) {
				acc.push(base)
				return acc
			}

			if (!down) {
				acc.push(rand(prev, height - 5))
				return acc
			}

			acc.push(acc[delta - 1])
			return acc
		}, [])(Array.from({ length: width }))
	})(Array.from({ length }))

const randomisePatterns = R.map(row => {
	const adjust = rand(-10, 10)
	const reverse = rand(1, 2) % 2 === 0
	const noise = R.map(item => item + adjust)(row)

	if (reverse) {
		return R.reverse(noise)
	}

	return noise
})

export const generateSkyLine = () => [
	...randomisePatterns([
		[94, 97, 98, 87, 74, 87, 78, 57, 94],
		[94, 87, 78, 87, 74, 67, 98, 37, 94],
		[93, 100, 90, 80, 90, 70, 94, 93],
		[93, 88, 137, 148, 148, 137, 123, 93],
		[37, 60, 93, 91, 29, 33],
		[66, 77, 66, 77, 66, 77, 66],
		[66, 77, 66, 77, 66, 77, 66],
		[37, 60, 93, 21, 29, 33],
		[91, 84, 76, 68, 56],
		[91, 84, 76, 68, 56],
		[39, 31],
		[39, 31],
		[55, 26],
		[82],
		[82],
		[134],
		[134]
	]),
	...makeChurch(50),
	...makeBuildings(200)
]

export const makeCity = R.compose(
	R.take(1000),
	mapIndexed((item, index) => ({
		height: item,
		y: 100 - item,
		x: index * 8,
		key: `skyline-${index}`
	})),
	R.flatten,
	shuffle
)
