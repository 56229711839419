/* eslint-disable react/no-multi-comp, react/button-has-type */

import React, { Component } from 'react'

export default class Button extends Component {
	render() {
		const {
			children,
			onClick,
			type = 'button',
			bg = ['teal', 'orange'],
			position = 'between',
			className = '',
			rounded = true,
			...rest
		} = this.props

		return (
			<button
				{...rest}
				type={type}
				onClick={onClick}
				className={`w-full text-body-fluid md:max-w-350 ${
					rounded ? 'rounded-sm' : ''
				} bg-${bg[0]} hover:bg-${bg[1]} focus:bg-${
					bg[1]
				} p-0-75 inline-flex outline-none items-center justify-${position} trans trans-bg trans-slow ${className}`}
			>
				{children}
			</button>
		)
	}
}
