import 'intersection-observer'
// import FontFaceObserver from 'fontfaceobserver'
import wrapWithProvider from '@/store/provider'
import WebFont from 'webfontloader'
import raf from 'raf-throttle'

export const wrapRootElement = wrapWithProvider

function handleFirstTab(e) {
	if (e.keyCode === 9) {
		document.documentElement.classList.add('user-is-tabbing')

		window.removeEventListener('keydown', handleFirstTab)
		window.addEventListener('mousedown', handleMouseDownOnce) // eslint-disable-line no-use-before-define
	}
}

function handleMouseDownOnce() {
	document.documentElement.classList.remove('user-is-tabbing')

	window.removeEventListener('mousedown', handleMouseDownOnce)
	window.addEventListener('keydown', handleFirstTab)
}

export const onClientEntry = () => {
	window.addEventListener('keydown', handleFirstTab)

	const vh = window.innerHeight * 0.01
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`)

	window.addEventListener(
		'resize',
		raf(() => {
			const vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		})
	)

	WebFont.load({
		custom: {
			families: ['GT-Walsheim-Light', 'GT-Walsheim-Medium'],
			urls: ['/fonts/fonts.css']
		}
	})
}

export const shouldUpdateScroll = () => true

export const onRouteUpdate = () => {
	// just incase it doesn't get removed through the react lifecycle
	document.body.classList.remove('menu-open')
}

window.log = () => {} // eslint-disable-line no-multi-assign

export const onServiceWorkerUpdateFound  = () => {
	window.location.reload(true)
}
