const imageFragment = `
		image {
			... on ImagesVolume {
				optimisedImage {
					... on OptimizedImagesData {
						src
						srcset
						srcWebp
						srcsetWebp
						maxSrcsetWidth
						placeholderWidth
						placeholderHeight
						colorPalette
					}
				}
			}
		}
	`

const commonFragment = `
		title
		subtitle
		uri
		id
		tags {
			title
			id
		}
	`

export default `query($term: String!) {
  entries(section: [blog, video, audio, directory], search: $term, order: "score asc") {
    __typename
    ... on Blog {
      ${commonFragment}
			${imageFragment}
			contentBlocks {
				__typename
				... on ContentBlocksRichText {
					body {
						content
					}
				}
			}
    }
    ... on Audio {
      ${commonFragment}
			${imageFragment}
			body {
				content
			}
    }
    ... on Video {
      ${commonFragment}
			${imageFragment}
			body {
				content
			}
		}
		
		... on Directory {
			title
			id
			address
			telephone
			website
			location
			tags {
				title
				id
			}
			directoryImage {
				... on DirectoryVolume {
					optimisedDirectory {
						... on OptimizedImagesData {
							src
							srcset
							srcWebp
							srcsetWebp
							maxSrcsetWidth
							placeholderWidth
							placeholderHeight
							colorPalette
						}
					}
				}
			}
		}
  }
}
`
